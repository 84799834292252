@import "~@laingorourke/core-web-components/scss/LORApp.scss";

.card-container header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

textarea.form-control {
    min-height: 100px;
}

.dead-letters-pop-up {
    .dead-letter-editor {
        height: 600px;
        width: 100%;
    }
}

.table {
    .action-panel span {
        padding-right: 5px;

        &:last-child {
            padding-right: 0;
        }
    }
}
